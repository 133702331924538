exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-biografia-js": () => import("./../../../src/pages/biografia.js" /* webpackChunkName: "component---src-pages-biografia-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-editorial-js": () => import("./../../../src/pages/editorial.js" /* webpackChunkName: "component---src-pages-editorial-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-moda-js": () => import("./../../../src/pages/moda.js" /* webpackChunkName: "component---src-pages-moda-js" */),
  "component---src-pages-novias-js": () => import("./../../../src/pages/novias.js" /* webpackChunkName: "component---src-pages-novias-js" */),
  "component---src-pages-prueba-js": () => import("./../../../src/pages/prueba.js" /* webpackChunkName: "component---src-pages-prueba-js" */),
  "component---src-pages-video-js": () => import("./../../../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */),
  "component---src-templates-editorial-js": () => import("./../../../src/templates/editorial.js" /* webpackChunkName: "component---src-templates-editorial-js" */),
  "component---src-templates-moda-js": () => import("./../../../src/templates/moda.js" /* webpackChunkName: "component---src-templates-moda-js" */),
  "component---src-templates-novias-js": () => import("./../../../src/templates/novias.js" /* webpackChunkName: "component---src-templates-novias-js" */),
  "component---src-templates-video-js": () => import("./../../../src/templates/video.js" /* webpackChunkName: "component---src-templates-video-js" */)
}

