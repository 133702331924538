export const brandTheme = {
  styles: {
    global: {
      "html, body, p": {
        color: "brand.body",
      },
    },
  },
  colors: {
    brand: {
      main: "#ffc29b",
      transparentMain: "rgba(254,191,156,0.7)",
      secondary: "#e69136",
      body: "#5A5551",
    },
  },
  fonts: {
    body: "Montserrat",
    heading: "Raleway",
  },
}
